import { Card, CardAdditional, CardText, CardTitle } from '../styled/Card';
import SubscribeForm from '../form/SubscribeForm';
import { City } from '../../models';
import { useModal } from '../../providers/modal';
import {
  ModalContent,
  ModalContentWrapper,
  ModalTitle,
  ModalTitleWrapper,
} from '../common/Modal';
import { H2 } from '../styled/typography';

export function Subscription({ currentCity }: { currentCity?: City }) {
  const modalState = useModal();
  if (!modalState) {
    return null;
  }
  return (
    <Card modifications={['colorRed', 'fullHeight', 'flex']}>
      <CardTitle>Рассылка</CardTitle>
      <CardText>
        <p>
          Хотите быть в&nbsp;курсе выгодных предложений? Подписывайтесь
          на&nbsp;рассылку! Раз в&nbsp;неделю будем присылать вам лучшие акции.
        </p>
      </CardText>
      <CardAdditional>
        {currentCity && (
          <SubscribeForm
            placeholder='Введите ваш e-mail'
            source='subscriptionBlock'
            currentCity={currentCity}
            onSuccessSubscribe={() => {
              modalState.setModalState({
                show: true,
                options: {
                  width: 400,
                },
                content: (
                  <ModalContentWrapper>
                    <ModalTitleWrapper
                      setModalState={modalState.setModalState}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      css={{
                        paddingTop: 40,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      <ModalTitle>
                        <H2
                          css={{
                            fontSize: 18,
                            fontWeight: 800,
                            lineHeight: '1.39',
                            flex: '1 0 auto',
                          }}
                        >
                          Осталось совсем чуть-чуть
                        </H2>
                      </ModalTitle>
                    </ModalTitleWrapper>
                    <ModalContent
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      css={{
                        paddingBottom: 30,
                        paddingTop: 30,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      <div
                        css={{
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: '1.43',
                          marginBottom: 30,
                        }}
                      >
                        Вы почти подписались &ndash; проверьте, пожалуйста,
                        почту!
                        <br />
                      </div>
                    </ModalContent>
                  </ModalContentWrapper>
                ),
              });
            }}
          />
        )}
      </CardAdditional>
    </Card>
  );
}
