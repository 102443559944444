import { useTheme } from '@emotion/react';
import { Card, CardAdditional, CardText, CardTitle } from '../styled/Card';
import { ButtonApp } from '../styled/Button';
import AppStoreSVG from '../../public/img/app-store.svg';
import GooglePlaySVG from '../../public/img/google-play.svg';
import AppGallerySVG from '../../public/img/app-gallery.svg';

export function MobileApps() {
  const theme = useTheme();
  return (
    <Card modifications={['colorRed', 'fullHeight', 'flex']}>
      <CardTitle>Приложение</CardTitle>
      <CardText>
        <p>Ваши любимые скидки теперь и&nbsp;в&nbsp;телефоне.</p>
      </CardText>
      <CardAdditional>
        <ButtonApp
          href='https://redirect.appmetrica.yandex.com/serve/890929829480791116'
          target='_blank'
        >
          <AppStoreSVG width='25px' height='25px' />
          <div
            css={{
              marginLeft: '12px',
            }}
          >
            <div
              css={{
                fontSize: '10px',
                letterSpacing: '1px',
                color: theme.colors.red,
                textTransform: 'uppercase',
              }}
            >
              Загрузите в
            </div>
            <div
              css={{
                color: theme.colors.red,
              }}
            >
              App Store
            </div>
          </div>
        </ButtonApp>

        <ButtonApp
          href='https://redirect.appmetrica.yandex.com/serve/530641817767550518'
          target='_blank'
        >
          <GooglePlaySVG width='20px' height='20px' />
          <div
            css={{
              marginLeft: '12px',
            }}
          >
            <div
              css={{
                fontSize: '10px',
                letterSpacing: '1px',
                color: theme.colors.red,
                textTransform: 'uppercase',
              }}
            >
              Загрузите в
            </div>
            <div
              css={{
                color: theme.colors.red,
              }}
            >
              Google Play
            </div>
          </div>
        </ButtonApp>

        <ButtonApp
          href='https://redirect.appmetrica.yandex.com/serve/604901910461350927'
          target='_blank'
        >
          <AppGallerySVG width='20px' height='20px' />
          <div
            css={{
              marginLeft: '12px',
            }}
          >
            <div
              css={{
                fontSize: '10px',
                letterSpacing: '1px',
                color: theme.colors.red,
                textTransform: 'uppercase',
              }}
            >
              Загрузите в
            </div>
            <div
              css={{
                color: theme.colors.red,
              }}
            >
              AppGallery
            </div>
          </div>
        </ButtonApp>
      </CardAdditional>
    </Card>
  );
}
