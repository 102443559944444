import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from '../../styled/Form';
import { SubscribeInput } from '../input';
import email from '../../../utils/validators';
import { http } from '../../../lib/api/http';
import { V2 } from '../../../lib/api/urls';
import { City } from '../../../models';

yup.addMethod(yup.string, 'email', email);

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Поле обязательно')
    .email('Неверно введён email'),
});

function SubscribeForm({
  eventId,
  currentCity,
  onSuccessSubscribe,
  defaultStyle,
  source,
  placeholder = 'Ваш E-mail',
}: {
  eventId?: number;
  source: string;
  placeholder?: string;
  defaultStyle?: boolean;
  onSuccessSubscribe: (email: string) => void;
  currentCity: City;
}) {
  const theme = useTheme();
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  function onSubmit({ email: emailValue }: { email: string }) {
    const data: {
      city: string;
      email: string;
      source: string;
      event_id?: number;
    } = {
      source,
      city: currentCity.remote_id,
      email: emailValue,
    };
    if (eventId) {
      data.event_id = eventId;
    }
    http.post(V2.subscriptions, data).then(() => {
      onSuccessSubscribe(data.email);
      reset({ email: '' });
    });
  }

  return (
    <Form
      css={{
        display: 'flex',
        width: '100%',
        maxWidth: 350,
        input: { ...theme.mixins.fonts.golos },
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <SubscribeInput
        defaultStyle={defaultStyle}
        placeholder={placeholder}
        control={control}
        name='email'
        registerProps={register('email')}
      />
      <button
        disabled={!isValid}
        type='submit'
        css={{
          outline: 0,
          fontSize: 14,
          fontWeight: 500,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          svg: {
            transition: 'all .2s ease',
          },
          ':hover': {
            svg: {
              marginLeft: 10,
            },
          },
          height: 50,
          width: 80,
          border: 'none',
          borderRadius: '0 4px 4px 0',
          backgroundColor: defaultStyle ? theme.colors.red : theme.colors.white,
          color: defaultStyle ? theme.colors.white : theme.colors.red,
        }}
      >
        <svg
          css={{
            width: 20,
            height: 20,
            fill: defaultStyle ? theme.colors.white : theme.colors.red,
          }}
        >
          <use xlinkHref='#arrow-right' />
        </svg>
      </button>
    </Form>
  );
}

export default SubscribeForm;
