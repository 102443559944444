import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';

export function Card({
  modifications = [],
  children,
}: {
  modifications: string[];
  children: ReactNode;
}) {
  const theme = useTheme();
  const modificationsStyle = {
    colorRed: {
      backgroundColor: theme.colors.red,
      color: theme.colors.white,
    },
    fullHeight: {
      height: '100%',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  };
  const assignedModifications = Object.assign(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...modifications.reduce((acc, item) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (modificationsStyle[item]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc.push(modificationsStyle[item]);
      }
      return acc;
    }, [])
  );
  return (
    <div
      css={{
        fontFamily: 'Golos',
        position: 'relative',
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        borderRadius: theme.constants.borderRadius,
        padding: '40px',
        height: 'auto',
        textAlign: 'left',
        fontWeight: 500,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...assignedModifications,
        [DEVICE_SCREENS_MAX_STRING.xl]: {
          padding: '16px 20px',
        },
      }}
    >
      {children}
    </div>
  );
}

export const CardTitle = styled.div`
  font-family: 'Montserrat';
  font-size: 44px;
  font-weight: 800;
  line-height: 1.05;
  margin-bottom: 0.5em;
  ${DEVICE_SCREENS_MAX_STRING.xl} {
    font-size: 24px;
    margin-bottom: 0.5em;
  }
  ${DEVICE_SCREENS_MAX_STRING.xl} {
    font-size: 32px;
  }
`;

export const CardText = styled.div`
  margin-bottom: 1em;
  ${DEVICE_SCREENS_MAX_STRING.xl} {
    font-size: 12px;
  }
  ${DEVICE_SCREENS_MAX_STRING.md} {
    font-size: 14px;
  }
`;

export const CardAdditional = styled.div`
  display: flex;
  ${DEVICE_SCREENS_MAX_STRING.xl} {
    flex-direction: column;
    font-size: 12px;
  }
`;
